import React, { useEffect, useCallback, useRef } from "react";
import "./infra.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSchoolStatsData } from "../../redux/thunks/dashboardThunk";
import { fetchSchoolStatsDataYear } from "../../redux/thunks/dashboardThunk";
import { fetchArchiveServicesSchoolData } from "../../redux/thunks/archiveServicesThunk";
import {
  state_gov_mgt_code,
  gov_aided_mgt_code,
  pvt_uaided_mgt_code,
  ctrl_gov_mgt_code,
  other_mgt_code,
  pr_sch_code,
  upr_pr_code,
  hr_sec_code,
  sec_sch_code,
  pre_pr_sch_code,
  selectedDYear,
  nationalWiseName,
  stateWiseName,
  districtWiseName,
  district,
  blockWiseName,
  block,
  generateTextContent,
} from "../../constants/constants";
import allreportsdata from "../../json-data/allreports.json";
import allReportsHindidata from "../../json-data/allReportsHindi.json";
import { ScrollToTopOnMount } from "../Scroll/ScrollToTopOnMount";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import groupByKey from "../../utils/groupBy";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Highcharts, { color } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { initialFilterSchoolData } from "../../constants/constants";
import HC_more from "highcharts/highcharts-more";
import FilterDropdown3016 from "../Home/filter/FilterDropdown3016";
import { useLocation, useSearchParams } from "react-router-dom";
import { GlobalLoading } from "../GlobalLoading/GlobalLoading";
import { categoryMappings as categoryMappingsOriginal } from "../../constants/constants";
import { removeAllDistrict } from "../../redux/thunks/districtThunk";
import { removeAllBlock } from "../../redux/thunks/blockThunk";
import { handleActiveTabs } from "../../redux/slice/headerSlice";
import { useTranslation } from "react-i18next";
import FilterDropdownCommom358 from "../Home/filter/FilterDropdownCommon358";
import { handleMissingData } from "../../utils/handleMissingData";
import satyamevaimg from "../../assets/images/satyameva-jayate-img.png";
import udise from "../../assets/images/udiseplu.jpg";
import useReportOverallLocationSum from "../../CustomHook/useReportOverallLocationSum";

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);
require("highcharts/modules/export-data.js")(Highcharts);
require("highcharts/highcharts-more")(Highcharts);
require("highcharts/modules/treemap")(Highcharts);
require("highcharts/modules/treegraph")(Highcharts);
HC_more(Highcharts);

export default function EnrollmentReport4000() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [queryParameters] = useSearchParams();
  const id = queryParameters.get("id");
  const type = queryParameters.get("type");
  const location = useLocation();
  const queryString = window.location.hash.substring(1);
  const urlParams = new URLSearchParams(queryString.split("?")[1]);
  const paramValue = urlParams.get("type");
  const school_data = useSelector((state) => state.school);

  const schoolFilter = useSelector((state) => state.schoolFilter3016);
  const headerData = useSelector((state) => state.header);
  // const local_state = window.localStorage.getItem("state_wise");
  const [local_state, setLocalStateName] = useState("All India/National");
  const [local_district, setLocalDistrictName] = useState("District");
  const [local_block, setLocalBlockName] = useState("Block");
  const local_year = window.localStorage.getItem("year");
  const stateName = localStorage.getItem("state");
  const filterObj = structuredClone(schoolFilter);
  const [report, setReport] = useState(null);
  const [gridApi, setGridApi] = useState();
  const [arrGroupedData, setArrGroupedData] = useState([]);
  const [activeTab, setActiveTab] = useState(type);
  const [isScrollComplete, setIsScrollComplete] = useState(false);
  dispatch(handleActiveTabs(activeTab));
  const [groupKeys, setGroupKeys] = useState({
    rateWise: true,
    categoriesWise: false,
  });

  useEffect(() => {
    dispatch(removeAllDistrict());
    dispatch(removeAllBlock());
  }, []);
  useEffect(() => {
    window.localStorage.setItem("map_state_name", "All India/National");
    window.localStorage.setItem("state", "All India/National");
    window.localStorage.setItem("state_wise", "All India/National");
    window.localStorage.setItem("map_district_name", "District");
    window.localStorage.setItem("district", "District");
    window.localStorage.setItem("block", "Block");
  }, []);

  useEffect(() => {
    setLocalStateName(localStorage.getItem("state"));
    setLocalStateName(localStorage.getItem("state_wise"));
    setLocalStateName(localStorage.getItem("map_state_name"));

    setLocalDistrictName(localStorage.getItem("map_district_name"));
    setLocalBlockName(localStorage.getItem("block"));
  }, [filterObj, headerData.activeTab]);

  const [enabledScType, setEnabledSchType] = useState(false);
  const [enabledSchLocation, setEnabledSchLocations] = useState(false);
  const [showTransposed, setShowTransposeds] = useState(false);
  const [gridRefreshKey, setGridRefreshKey] = useState(0);
  const [showTransposedMgts, setShowTransposedMgts] = useState(false);
  const [rate, setRate] = useState("active");
  const [categories, setCategories] = useState("active");

  const [promotionRate, setPromotionRate] = useState("");
  const [repetitionRate, setRepetitionRate] = useState("");
  const [dropoutRate, setDropoutRate] = useState("");
  const [transitionRate, setTransitionRate] = useState("");
  const [allRateWise, setAllRateWise] = useState("");
  const [five, setFive] = useState("");
  const [six, setSix] = useState("");
  const [seven, setSeven] = useState("");
  const [eight, setEight] = useState("");
  const [multiRate, setMultiRate] = useState("multibtn");

  const [multiCat, setMultiCats] = useState("");

  const totalLabel = t("total");

  const filter_query_by_location =
    (local_state === "All India/National" &&
      groupKeys.schCategoryBroad &&
      groupKeys.schCategoryDesc) ||
    local_state === "State Wise" ||
    local_district === "District Wise" ||
    local_block === "Block Wise";

  // Define Header Groups
  const rateWiseHeaders = [
    {
      headerName: "Primary", // Parent Header
      children: [
        {
          headerName: "Enrolment II to VI", // Child Header
          children: [
            { headerName: "Girls (1)", field: "enrolmentGirls", hide: false },
            { headerName: "Boys (2)", field: "enrolmentBoys", hide: false },
            { headerName: "Total (3)", field: "enrolmentTotal", hide: false },
          ],
        },
        {
          headerName: "Repeater II to VI", // Child Header
          children: [
            { headerName: "Girls (4)", field: "repeaterGirls", hide: false },
            { headerName: "Boys (5)", field: "repeaterBoys", hide: false },
            { headerName: "Total (6)", field: "repeaterTotal", hide: false },
          ],
        },
        {
          headerName: "Fresh Enrolment", // Child Header
          children: [
            { headerName: "(1-4) Girls (7)", field: "freshGirls", hide: false },
            { headerName: "(2-5) Boys (8)", field: "freshBoys", hide: false },
            { headerName: "(3-6) Total (9)", field: "freshTotal", hide: false },
          ],
        },
        {
          headerName: "Repeater I to V", // Child Header
          children: [
            { headerName: "Girls (10)", field: "repeaterIVGirls", hide: false },
            { headerName: "Boys (11)", field: "repeaterIVBoys", hide: false },
            { headerName: "Total (12)", field: "repeaterIVTotal", hide: false },
          ],
        },
        {
          headerName: "Enrolment Previous Year I-V", // Child Header
          children: [
            { headerName: "Girls (13)", field: "prevGirls", hide: false },
            { headerName: "Boys (14)", field: "prevBoys", hide: false },
            { headerName: "Total (15)", field: "prevTotal", hide: false },
          ],
        },
        {
          headerName: "Promotion Rate", // Child Header
          children: [
            {
              headerName: "(7/13) Girls (16)",
              field: "promotionGirls",
              hide: false,
            },
            {
              headerName: "(8/14) Boys (17)",
              field: "promotionBoys",
              hide: false,
            },
            {
              headerName: "(9/15) Overall (18)",
              field: "promotionTotal",
              hide: false,
            },
          ],
        },
        {
          headerName: "Repetition Rate", // Child Header
          children: [
            {
              headerName: "(10/13) Girls (19)",
              field: "repetitionGirls",
              hide: false,
            },
            {
              headerName: "(11/14) Boys (20)",
              field: "repetitionBoys",
              hide: false,
            },
            {
              headerName: "(12/15) Overall (21) ",
              field: "repetitionTotal",
              hide: false,
            },
          ],
        },
        {
          headerName: "Drop Out Rate", // Child Header
          children: [
            { headerName: "Girls", field: "dropOutGirls", hide: false },
            { headerName: "Boys", field: "dropOutBoys", hide: false },
            { headerName: "Overall", field: "dropOutTotal", hide: false },
          ],
        },
      ],
    },

    {
      headerName: "Upper Primary", // Parent Header
      children: [
        {
          headerName: "Enrolment VII to IX", // Child Header
          children: [
            { headerName: "Girls (1)", field: "enrolmentGirls", hide: false },
            { headerName: "Boys (2)", field: "enrolmentBoys", hide: false },
            { headerName: "Total (3)", field: "enrolmentTotal", hide: false },
          ],
        },
        {
          headerName: "Repeater VII to IX ", // Child Header
          children: [
            { headerName: "Girls (4)", field: "repeaterGirls", hide: false },
            { headerName: "Boys (5)", field: "repeaterBoys", hide: false },
            { headerName: "Total (6)", field: "repeaterTotal", hide: false },
          ],
        },
        {
          headerName: "Fresh Enrolment", // Child Header
          children: [
            { headerName: "(1-4) Girls (7)", field: "freshGirls", hide: false },
            { headerName: "(2-5) Boys (8)", field: "freshBoys", hide: false },
            { headerName: "(3-6) Total (9)", field: "freshTotal", hide: false },
          ],
        },
        {
          headerName: "Repeater VI to VIII", // Child Header
          children: [
            { headerName: "Girls (10)", field: "repeaterIVGirls", hide: false },
            { headerName: "Boys (11)", field: "repeaterIVBoys", hide: false },
            { headerName: "Total (12)", field: "repeaterIVTotal", hide: false },
          ],
        },
        {
          headerName: "Enrolment Previous Year VI-VII", // Child Header
          children: [
            { headerName: "Girls (13)", field: "prevGirls", hide: false },
            { headerName: "Boys (14)", field: "prevBoys", hide: false },
            { headerName: "Total (15)", field: "prevTotal", hide: false },
          ],
        },
        {
          headerName: "Promotion Rate", // Child Header
          children: [
            {
              headerName: "(7/13) Girls (16)",
              field: "promotionGirls",
              hide: false,
            },
            {
              headerName: "(8/14) Boys (17)",
              field: "promotionBoys",
              hide: false,
            },
            {
              headerName: "(9/15) Overall (18)",
              field: "promotionTotal",
              hide: false,
            },
          ],
        },
        {
          headerName: "Repetition Rate", // Child Header
          children: [
            {
              headerName: "(10/13) Girls (19)",
              field: "repetitionGirls",
              hide: false,
            },
            {
              headerName: "(11/14) Boys (20)",
              field: "repetitionBoys",
              hide: false,
            },
            {
              headerName: "(12/15) Overall (21) ",
              field: "repetitionTotal",
              hide: false,
            },
          ],
        },
        {
          headerName: "Drop Out Rate", // Child Header
          children: [
            { headerName: "Girls", field: "dropOutGirls", hide: false },
            { headerName: "Boys", field: "dropOutBoys", hide: false },
            { headerName: "Overall", field: "dropOutTotal", hide: false },
          ],
        },
      ],
    },

    {
      headerName: "Secondary", // Parent Header
      children: [
        {
          headerName: "Enrolment X to XI", // Child Header
          children: [
            { headerName: "Girls (1)", field: "enrolmentGirls", hide: false },
            { headerName: "Boys (2)", field: "enrolmentBoys", hide: false },
            { headerName: "Total (3)", field: "enrolmentTotal", hide: false },
          ],
        },
        {
          headerName: "Repeater X to XI  ", // Child Header
          children: [
            { headerName: "Girls (4)", field: "repeaterGirls", hide: false },
            { headerName: "Boys (5)", field: "repeaterBoys", hide: false },
            { headerName: "Total (6)", field: "repeaterTotal", hide: false },
          ],
        },
        {
          headerName: "Fresh Enrolment X to XI", // Child Header
          children: [
            { headerName: "(1-4) Girls (7)", field: "freshGirls", hide: false },
            { headerName: "(2-5) Boys (8)", field: "freshBoys", hide: false },
            { headerName: "(3-6) Total (9)", field: "freshTotal", hide: false },
          ],
        },
        {
          headerName: "Repeater IX to X", // Child Header
          children: [
            { headerName: "Girls (10)", field: "repeaterIVGirls", hide: false },
            { headerName: "Boys (11)", field: "repeaterIVBoys", hide: false },
            { headerName: "Total (12)", field: "repeaterIVTotal", hide: false },
          ],
        },
        {
          headerName: "Enrolment Previous Year IX-X", // Child Header
          children: [
            { headerName: "Girls (13)", field: "prevGirls", hide: false },
            { headerName: "Boys (14)", field: "prevBoys", hide: false },
            { headerName: "Total (15)", field: "prevTotal", hide: false },
          ],
        },
        {
          headerName: "Promotion Rate", // Child Header
          children: [
            {
              headerName: "(7/13) Girls (16)",
              field: "promotionGirls",
              hide: false,
            },
            {
              headerName: "(8/14) Boys (17)",
              field: "promotionBoys",
              hide: false,
            },
            {
              headerName: "(9/15) Overall (18)",
              field: "promotionTotal",
              hide: false,
            },
          ],
        },
        {
          headerName: "Repetition Rate", // Child Header
          children: [
            {
              headerName: "(10/13) Girls (19)",
              field: "repetitionGirls",
              hide: false,
            },
            {
              headerName: "(11/14) Boys (20)",
              field: "repetitionBoys",
              hide: false,
            },
            {
              headerName: "(12/15) Overall (21) ",
              field: "repetitionTotal",
              hide: false,
            },
          ],
        },
        {
          headerName: "Drop Out Rate", // Child Header
          children: [
            { headerName: "Girls", field: "dropOutGirls", hide: false },
            { headerName: "Boys", field: "dropOutBoys", hide: false },
            { headerName: "Overall", field: "dropOutTotal", hide: false },
          ],
        },
      ],
    },
  ];

  const promotionRateHeaders = [
    {
      headerName: "Primary Promotion Rate", // Parent Header

      children: [
        {
          headerName: "(7/13) Girls (16)", // Child Header
          field: "girls", // Field name in your data
          hide: false,
        },
        {
          headerName: "(8/14) Boys (17)", // Child Header
          field: "boys", // Field name in your data
          hide: false,
        },
        {
          headerName: "(9/15) Overall (18)", // Child Header
          field: "overall", // Field name in your data
          hide: false,
        },
      ],
    },
    {
      headerName: "Upper Primary Promotion rate", // Parent Header
      children: [
        {
          headerName: "(7/13) Girls (16)", // Child Header
          field: "girls", // Field name in your data
          hide: false,
        },
        {
          headerName: "(8/14) Boys (17)", // Child Header
          field: "boys", // Field name in your data
          hide: false,
        },
        {
          headerName: "(9/15) Overall (18)", // Child Header
          field: "overall", // Field name in your data
          hide: false,
        },
      ],
    },
    {
      headerName: "Secondary Promotion rate", // Parent Header
      children: [
        {
          headerName: "(7/13) Girls (16)", // Child Header
          field: "girls", // Field name in your data
          hide: false,
        },
        {
          headerName: "(8/14) Boys (17)", // Child Header
          field: "boys", // Field name in your data
          hide: false,
        },
        {
          headerName: "(9/15) Overall (18)", // Child Header
          field: "overall", // Field name in your data
          hide: false,
        },
      ],
    },
  ];

  const repetitionRateHeaders = [
    {
      headerName: "Primary Repetition Rate", // Parent Header

      children: [
        {
          headerName: "(10/13) Girls (19)", // Child Header
          field: "girls", // Field name in your data
          hide: false,
        },
        {
          headerName: "(11/14) Boys (20)", // Child Header
          field: "boys", // Field name in your data
          hide: false,
        },
        {
          headerName: "(12/15) Overall (21)", // Child Header
          field: "overall", // Field name in your data
          hide: false,
        },
      ],
    },
    {
      headerName: "Upper Primary Repetition Rate", // Parent Header

      children: [
        {
          headerName: "(10/13) Girls (19)", // Child Header
          field: "girls", // Field name in your data
          hide: false,
        },
        {
          headerName: "(11/14) Boys (20)", // Child Header
          field: "boys", // Field name in your data
          hide: false,
        },
        {
          headerName: "(12/15) Overall (21)", // Child Header
          field: "overall", // Field name in your data
          hide: false,
        },
      ],
    },
    {
      headerName: " Secondary Repetition Rate", // Parent Header

      children: [
        {
          headerName: "(10/13) Girls (19)", // Child Header
          field: "girls", // Field name in your data
          hide: false,
        },
        {
          headerName: "(11/14) Boys (20)", // Child Header
          field: "boys", // Field name in your data
          hide: false,
        },
        {
          headerName: "(12/15) Overall (21)", // Child Header
          field: "overall", // Field name in your data
          hide: false,
        },
      ],
    },
  ];

  const dropoutRateHeaders = [
    {
      headerName: "Primary Drop Out Rate", // Parent Header

      children: [
        {
          headerName: "Girls", // Child Header
          field: "girls", // Field name in your data
          hide: false,
        },
        {
          headerName: "Boys", // Child Header
          field: "boys", // Field name in your data
          hide: false,
        },
        {
          headerName: "Boys", // Child Header
          field: "overall", // Field name in your data
          hide: false,
        },
      ],
    },
    {
      headerName: "Upper Primary Drop Out Rate", // Parent Header

      children: [
        {
          headerName: "Girls", // Child Header
          field: "girls", // Field name in your data
          hide: false,
        },
        {
          headerName: "Boys", // Child Header
          field: "boys", // Field name in your data
          hide: false,
        },
        {
          headerName: "Boys", // Child Header
          field: "overall", // Field name in your data
          hide: false,
        },
      ],
    },
    {
      headerName: "Secondary Drop Out Rate", // Parent Header

      children: [
        {
          headerName: "Girls", // Child Header
          field: "girls", // Field name in your data
          hide: false,
        },
        {
          headerName: "Boys", // Child Header
          field: "boys", // Field name in your data
          hide: false,
        },
        {
          headerName: "Boys", // Child Header
          field: "overall", // Field name in your data
          hide: false,
        },
      ],
    },
  ];

  const transitionRateHeaders = [
    {
      headerName: "Transition Rate",
      children: [
        {
          headerName: "Primary to Upper Primary (5 to 6)",
          children: [
            { field: "primaryGirls", headerName: "Girls" },
            { field: "primaryBoys", headerName: "Boys" },
            { field: "primaryOverall", headerName: "Overall" },
          ],
        },
        {
          headerName: "Elementary To Secondary (8 to 9)",
          children: [
            { field: "secondaryGirls", headerName: "Girls" },
            { field: "secondaryBoys", headerName: "Boys" },
            { field: "secondaryOverall", headerName: "Overall" },
          ],
        },
        {
          headerName: "Secondary to Higher Secondary (10 to 11)",
          children: [
            { field: "elementaryGirls", headerName: "Girls" },
            { field: "elementaryBoys", headerName: "Boys" },
            { field: "elementaryOverall", headerName: "Overall" },
          ],
        },
      ],
    },
  ];

  const [columns, setColumn] = useState([
    {
      headerName: "Serial Number",
      field: "Serial Number",
      hide: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },

    {
      headerName: "Location",
      field: "regionName",
      hide: false,
    },
  ]);

  // useEffect(() => {
  //   // Add dynamic headers based on conditions
  //   let updatedColumns;
  //   if (groupKeys.rateWise === true) {
  //     updatedColumns = [...rateWiseHeaders];
  //   }

  //   if (promotionRate === "active") {
  //     updatedColumns = [...promotionRateHeaders];
  //   }
  //   if(repetitionRate === "active"){
  //     updatedColumns = [...repetitionRateHeaders ]
  //   }
  //   if(dropoutRateHeaders ==="active"){
  //     updatedColumns = [...dropoutRateHeaders]
  //   }
  //   if(transitionRate === "active"){
  //     updatedColumns = [...transitionRateHeaders ]
  //   }

  //   setColumn(updatedColumns);
  // }, [groupKeys, promotionRate,repetitionRate,dropoutRateHeaders,transitionRate]);

  const [defColumnDefs] = useState({
    flex: 1,
    minWidth: 150,
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
    filter: true,
    autoHeaderHeight: true,
  });

  // Taking the both reports data condition wise for language change
  useEffect(() => {
    const reportsData =
      i18n.language === "hi" ? allReportsHindidata : allreportsdata;

    for (const category in reportsData) {
      const foundReport = reportsData[category].find(
        (report) => report.id === parseInt(id)
      );
      if (foundReport) {
        setReport(foundReport);
        break;
      }
    }
  }, [id, i18n.language]);

  const onGridReady = useCallback((params) => {
    setGridApi(params);
  }, []);

  const handleFilter = (value, e) => {
    if (
      value === "Rate Wise" ||
      value === "promotionRate" ||
      value === "repetitionRate" ||
      value === "dropoutRate" ||
      value === "transitionRate" ||
      value === "allRateWise"
    ) {
      if (value === "allRateWise") {
        // setRate("active");
        setAllRateWise("active");
        if (allRateWise === "active") {
          setAllRateWise("");
        }

        setPromotionRate("");
        setRepetitionRate("");
        setDropoutRate("");
        setTransitionRate("");

        setCategories("");
      }

      if (value === "promotionRate") {
        // setRate("");
        setAllRateWise("");

        // setRepetitionRate("");
        // setDropoutRate("");
        // setTransitionRate("");

        setPromotionRate("active");
        if (promotionRate === "active") {
          setPromotionRate("");
        }
        if (allRateWise === "") {
          setPromotionRate("active");
        }
      }
      if (value === "repetitionRate") {
        // setRate("");
        setAllRateWise("");

        // setPromotionRate("");
        // setDropoutRate("");
        // setTransitionRate("");

        setRepetitionRate("active");

        if (repetitionRate === "active") {
          setRepetitionRate("");
        }
      }
      if (value === "dropoutRate") {
        // setRate("");
        setAllRateWise("");

        // setPromotionRate("");
        // setRepetitionRate("");
        // setDropoutRate("");
        //  setTransitionRate("");
        setDropoutRate("active");
        if (dropoutRate === "active") {
          setDropoutRate("");
        }
      }
      if (value === "transitionRate") {
        // setRate("");
        setAllRateWise("");

        // setPromotionRate("");
        // setRepetitionRate("");
        // setDropoutRate("");

        setTransitionRate("active");
        if (transitionRate === "active") {
          setTransitionRate("");
        }
      }

      // if (value === "allRateWise") {
      //   setRate("");

      //   // setPromotionRate("");
      //   // setRepetitionRate("");
      //   // setDropoutRate("");

      //   setAllRateWise("active");
      //   // if (trans === "active") {
      //   //   setTransitionRate("");
      //   // }
      // }
    }

    if (
      value === "Categories" ||
      value === "five" ||
      value === "six" ||
      value === "seven" ||
      value === "eight"
    ) {
      if (value === "Categories") {
        setRate("");

        setFive("");
        setSix("");
        setSeven("");
        setEight("");

        setCategories("active");
      }

      if (value === "five") {
        setCategories("");
        setFive("active");

        // setSix("");
        // setSeven("");
        // setEight("");

        if (five === "active") {
          setFive("");
        }
      }

      if (value === "six") {
        setCategories("");
        setSix("active");

        // setFive("");
        // setSeven("");
        // setEight("");

        if (six === "active") {
          setSix("");
        }
      }

      if (value === "seven") {
        setCategories("");
        setSeven("active");

        // setFive("");
        // setSix("");
        // setEight("");

        if (seven === "active") {
          setSeven("");
        }
      }

      if (value === "eight") {
        setCategories("");
        setEight("active");

        // setFive("");
        // setSix("");
        // setSeven("");

        if (eight === "active") {
          setEight("");
        }
      }
    }
  };

  const handleGroupButtonClicks = (e) => {
    setGridRefreshKey((prevKey) => prevKey + 1);
    handleFilter(e);
    const updatedGroupKeys = { ...groupKeys };

    if (e === "Rate Wise") {
      updatedGroupKeys.rateWise = true;
      updatedGroupKeys.categoriesWise = false;
      setMultiRate("multibtn");
      setMultiCats("");
      // setMultiRate("active");
      // setRate("active");
      // setMultiCats("");
    } else if (e === "Categories") {
      updatedGroupKeys.categoriesWise = true;
      updatedGroupKeys.rateWise = false;
      setMultiRate("");
      setMultiCats("multibtn");
    }
    setGroupKeys(updatedGroupKeys);
  };

  useEffect(() => {
    handleGroupButtonClicks("Rate Wise", null);
  }, []);

  /*------------Export data to Excel and PDF-------------*/
  const getHeaderToExport = (gridApi) => {
    const columns = gridApi.api.getAllDisplayedColumns();
    const headerCellSerialNumber = {
      text: "Serial Number",
      headerName: "S.NO.",
      bold: true,
      margin: [0, 12, 0, 0],
    };
    const headerRow = columns.map((column) => {
      const { field, headerName } = column.getColDef();
      const sort = column.getSort();
      const headerNameUppercase = field[0].toUpperCase() + field.slice(1);
      const headerCell = {
        text: headerNameUppercase + (sort ? ` (${sort})` : ""),
        headerName: headerName,
        bold: true,
        margin: [0, 12, 0, 0],
      };
      return headerCell;
    });
    headerRow.unshift(headerCellSerialNumber);
    return headerRow;
  };
  const getRowsToExport = (gridApi) => {
    const columns = gridApi.api.getAllDisplayedColumns();
    const getCellToExport = (column, node) => ({
      text: gridApi.api.getValue(column, node) ?? "",
    });
    const rowsToExport = [];
    gridApi.api.forEachNodeAfterFilterAndSort((node) => {
      const rowToExport = [];
      rowToExport.push({ text: rowsToExport.length + 1 });
      columns.forEach((column) => {
        rowToExport.push(getCellToExport(column, node));
      });
      rowsToExport.push(rowToExport);
    });
    return rowsToExport;
  };
  const getDocument = (gridApi) => {
    const headerRow = getHeaderToExport(gridApi);
    const rows = getRowsToExport(gridApi);
    const date = new Date();
    // const formattedDate = new Intl.DateTimeFormat("en-US", {
    //   year: "numeric",
    //   month: "short",
    //   day: "2-digit",
    //   hour: "2-digit",
    //   minute: "2-digit",
    //   hour12: true,
    // }).format(date);
    const formattedDate = `${date.toLocaleDateString("en-GB", {
      weekday: "long", // Full day name (e.g., Friday)
    })}, ${date.toLocaleDateString("en-GB", {
      day: "2-digit", // Day of the month (e.g., 13)
      month: "long", // Full month name (e.g., September)
      year: "numeric", // Full year (e.g., 2024)
    })}, ${date.toLocaleTimeString("en-US", {
      hour: "2-digit", // Hour (e.g., 10)
      minute: "2-digit", // Minutes (e.g., 25)
      hour12: true, // 12-hour format with AM/PM
    })}`;

    const doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
      format: [12, 20],
    });
    // Function to add header
    const addHeader = () => {
      doc.setFontSize(22);
      doc.setTextColor("black");
      doc.setFont("Helvetica", "bold");
      // doc.text("UDISE+", 0.6, 1);

      // Add the header text
      doc.text(
        "Unified District Information System for Education",
        doc.internal.pageSize.width / 2,
        0.7,
        {
          fontStyle: "bold",

          color: "black",
          align: "center",
        }
      );
      doc.setFont("Helvetica", "normal");

      doc.setFontSize(18);

      doc.text(
        "Department of School Education & Literacy, Ministry of Education, Government of India",
        doc.internal.pageSize.width / 2,
        1.1,
        {
          // fontStyle: '',
          color: "black",
          align: "center",
        }
      );

      doc.setFont("Helvetica", "bold");

      doc.setTextColor("black");
      doc.setFontSize(20);

      doc.text(`${report.report_name}`, doc.internal.pageSize.width / 2, 1.5, {
        fontSize: 12,
        fontStyle: "bold",

        color: "black",
        align: "center",
      });

      doc.setFont("Helvetica", "bold");

      doc.setTextColor("black");
      doc.setFontSize(20);

      // doc.text(`${stateName}`, doc.internal.pageSize.width / 2, 1.7, {
      //   fontSize: 12,
      //   color: "black",
      //   align: "center",
      // });

      doc.setFont("bold");

      doc.setTextColor("black");
      doc.setFontSize(20);

      doc.text(
        `Academic Year : ${local_year}`,
        doc.internal.pageSize.width / 2,
        1.9,
        {
          fontSize: 12,
          color: "black",
          align: "center",
        }
      );

      const textContent = generateTextContent(
        stateName,
        nationalWiseName,
        stateWiseName,
        local_district,
        district,
        districtWiseName,
        local_block,
        block,
        blockWiseName
      );
      if (textContent) {
        doc.text(textContent, doc.internal.pageSize.width / 2, 2.3, {
          fontSize: 12,
          color: "black",
          align: "center",
        });
      }

      // Set the margin for the image from the left
      const leftMargin = 0.1; // Margin from the left (in inches)
      const topLeftX = leftMargin; // X position from the left including margin
      const topLeftY = 0; // Y position from the top (in inches)
      const imgWidth = 2; // Image width (in inches)
      const imgHeight = 2; // Image height (in inches)

      doc.setFontSize(20);
      doc.setTextColor("blue");

      // Add the satyameva image with the specified left margin
      doc.addImage(
        satyamevaimg,
        "PNG",
        topLeftX,
        topLeftY,
        imgWidth,
        imgHeight
      );

      doc.setTextColor("blue");
      doc.setFont("bold");

      // Get page dimensions
      const pageWidthE = doc.internal.pageSize.getWidth();
      const pageHeightE = doc.internal.pageSize.getHeight();

      const imgWidthE = 2.8; // Image width (in inches)
      const imgHeightE = 1.4; // Image height (in inches)
      const marginRight = 0.7; // Right margin (in inches)

      // Calculate x position for top-right corner
      const topRightX = pageWidthE - imgWidthE - marginRight;
      const topRightY = 0.3; // Y position from the top (in inches)

      // Add the education image at the top-right corner
      doc.addImage(
        udise,
        "JPG",
        topRightX, // X position for top-right
        topRightY, // Y position for top-right
        imgWidthE,
        imgHeightE
      );

      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();

      // Example: Draw a rectangle around the whole page
      // doc.setLineWidth(0.02); // Thicker border for the page
      // doc.rect(0.1, 0.1, pageWidth - 0.2, pageHeight - 0.2); // x, y, width, height
    };
    // Function to add footer
    const addFooter = () => {};
    const table = [];
    table.push(headerRow.map((cell) => cell.headerName));
    rows.forEach((row) => {
      table.push(row.map((cell) => cell.text));
    });
    addHeader();
    doc.autoTable({
      head: [table[0]],
      body: table.slice(1),
      theme: "grid",
      startY: 2.7,
      styles: {
        cellPadding: 0.15, // Adjust cell padding if needed
        lineColor: [0, 0, 0], // Set border color (black in this case)
        lineWidth: 0.001, // Set border width
        fillColor: [255, 255, 255], // Default background color (white)
        textColor: [0, 0, 0],
      },
      headStyles: {
        fontSize: 14, // Set the font size for the header row
        fontStyle: "bold", // Make the header text bold (optional)
        textColor: [0, 0, 0],
        cellPadding: 0.2, // Set text color for the header row
      },

      didParseCell: function (data) {
        const headerRow = getHeaderToExport(gridApi); // Get the header row

        // Get the header text for this column
        const columnHeaderText = headerRow[data.column.index]?.text;

        // Check if the current column header is "Serial Number"
        if (columnHeaderText === "Serial Number") {
          data.cell.styles.halign = "center"; // Center-align the content for "Serial Number"
        } else if (
          columnHeaderText === "RegionName" ||
          columnHeaderText === "Location" ||
          columnHeaderText === "regionName"
        ) {
          data.cell.styles.halign = "left"; // Center-align the content for "Serial Number"
        } else {
          data.cell.styles.halign = "right";
        }
      },

      afterPageContent: addFooter,
    });

    const totalPages = doc.internal.getNumberOfPages();
    doc.page = 1;
    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      doc.setFontSize(14);
      doc.setFont("helvetica", "normal");
      doc.setTextColor("black");
      doc.text(
        `Page ${i} of ${totalPages}`,
        doc.internal.pageSize.width / 2,
        doc.internal.pageSize.height - 0.2,
        { align: "center", color: "black" }
      );

      doc.text(
        `Report generated on : ${formattedDate}`,
        doc.internal.pageSize.width - 1,
        doc.internal.pageSize.height - 0.2,
        { fontSize: 12, align: "right", color: "black" }
      );
    }

    for (let i = 0; i < totalPages; i++) {
      doc.setPage(i + 1);
      doc.autoTable({
        startY: 3.5,
      });
    }
    return doc;
  };
  const exportToPDF = () => {
    const doc = getDocument(gridApi);
    doc.save(`${report.report_name}.pdf`);
  };
  const exportToExcel = () => {
    if (gridApi) {
      const allData = [];
      const visibleColumns = gridApi.api.getAllDisplayedColumns();
      const columnHeaders = visibleColumns.map((column) => ({
        headerName: column.getColDef().headerName,
        field: column.getColDef().field,
      }));
      columnHeaders.unshift({
        headerName: "S.NO.",
        field: "Serial Number",
      });
      gridApi.api.forEachNode((node, index) => {
        const data = node.data;
        const rowDataWithSerial = { ...data, "Serial Number": index + 1 };
        allData.push(rowDataWithSerial);
      });
      const columnKeys = columnHeaders.map((column) => column.field);
      const columnNames = columnHeaders.map((column) => column.headerName);
      gridApi.api.exportDataAsExcel({
        processCellCallback: (params) => {
          return params.value;
        },
        rowData: allData,
        fileName: report.report_name,
        sheetName: "Udise+",
        columnKeys: columnKeys,
        columnNames: columnNames,
      });
    }
  };
  const handleExportData = (e) => {
    const { value } = e.target;
    if (value === "export_pdf") {
      exportToPDF();
    }
    if (value === "export_excel") {
      exportToExcel();
    }
    document.getElementById("export_data").selectedIndex = 0;
  };

  const handleScrollComplete = () => {
    setIsScrollComplete(true);
  };

  return (
    <>
      <ScrollToTopOnMount onScrollComplete={handleScrollComplete} />
      <section className="infrastructure-main-card p-0" id="content">
        <div className="bg-grey2 pb-0 pt-0 header-bar tab-for-graph">
          <div className="blue-strip">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-10 col-lg-10">
                  <div className="common-content text-start map-heading-map">
                    {report && (
                      <div className="common-content text-start map-heading-map d-flex align-items-center">
                        <span className="me-3">
                          {t("reports_id")} {report.id}
                        </span>
                        <h2 className="heading-sm1 mb-0 mt-0">
                          {report.report_name}
                        </h2>
                      </div>
                    )}
                  </div>
                </div>
                {activeTab !== "about" && activeTab !== "graph" && (
                  <div className="col-md-2 col-lg-2">
                    <div className="select-infra button-group-filter">
                      <div className="indicator-select">
                        <select
                          id="export_data"
                          className="form-select bg-grey2"
                          onChange={handleExportData}
                          defaultValue={""}
                        >
                          <option className="option-hide">
                            {t("download_report")}
                          </option>
                          <option value="export_pdf">
                            {t("download_report")}{" "}
                          </option>
                          <option value="export_excel">
                            {t("download_as_excel")}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="container pb-4">
            <div className="row">
              {activeTab !== "about" && activeTab !== "graph" && (
                <div className="col-md-12 col-lg-12 d-flex align-items-center">
                  <div className="tab-text-infra me-4">{t("view_data_by")}</div>
                  <ul className="nav nav-tabs mul-tab-main">
                    <li className={`nav-item ${multiRate}`}>
                      <button
                        type="button"
                        className={`nav-link dark-active ${rate}`}
                        onClick={(e) => handleGroupButtonClicks("Rate Wise", e)}
                      >
                        Rate Wise
                      </button>
                      <button
                        type="button"
                        className={`nav-link dark-active details-multi ${promotionRate}`}
                        id="school_mgt_details"
                        onClick={(e) =>
                          handleGroupButtonClicks("promotionRate", e)
                        }
                      >
                        Promotion rate
                      </button>

                      <button
                        type="button"
                        className={`nav-link dark-active details-multi ${repetitionRate}`}
                        id="school_mgt_details"
                        onClick={(e) =>
                          handleGroupButtonClicks("repetitionRate", e)
                        }
                      >
                        Repetition Rate{" "}
                      </button>
                      <button
                        type="button"
                        className={`nav-link dark-active details-multi ${dropoutRate}`}
                        id="school_mgt_details"
                        onClick={(e) =>
                          handleGroupButtonClicks("dropoutRate", e)
                        }
                      >
                        Drop Out Rate{" "}
                      </button>
                      <button
                        type="button"
                        className={`nav-link dark-active details-multi ${transitionRate}`}
                        id="school_mgt_details"
                        onClick={(e) =>
                          handleGroupButtonClicks("transitionRate", e)
                        }
                      >
                        Transition Rate{" "}
                      </button>

                      <button
                        type="button"
                        className={`nav-link dark-active details-multi ${allRateWise}`}
                        id="school_mgt_details"
                        onClick={(e) =>
                          handleGroupButtonClicks("allRateWise", e)
                        }
                      >
                        All{" "}
                      </button>
                    </li>
                    <li className={`nav-item ${multiCat}`}>
                      <button
                        type="button"
                        className={`nav-link dark-active1 ${categories}`}
                        onClick={(e) =>
                          handleGroupButtonClicks("Categories", e)
                        }
                      >
                        Categories
                      </button>
                      <button
                        type="button"
                        className={`nav-link details-multi dark-active1 ${five}`}
                        onClick={(e) => handleGroupButtonClicks("five", e)}
                      >
                        {/* {t("detailed_view")} */}
                        Five
                      </button>

                      <button
                        type="button"
                        className={`nav-link details-multi dark-active1 ${six}`}
                        onClick={(e) => handleGroupButtonClicks("six", e)}

                        // onClick={(e) =>
                        //   handleGroupButtonClick("Cat Details", e)
                        // }
                      >
                        {/* {t("detailed_view")} */}
                        Six
                      </button>

                      <button
                        type="button"
                        className={`nav-link details-multi dark-active1 ${seven}`}
                        onClick={(e) => handleGroupButtonClicks("seven", e)}
                      >
                        Seven
                      </button>

                      <button
                        type="button"
                        className={`nav-link details-multi dark-active1 ${eight}`}
                        onClick={(e) => handleGroupButtonClicks("eight", e)}
                      >
                        Eight
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="bg-grey ptb-30">
          <div className="container tab-for-graph">
            <div className="row align-items-center report-inner-tab">
              <div className="col-md-12 col-lg-12 table-text-i table-brud-card">
                {activeTab !== "about" && (
                  <h4 className="brudcrumb_heading">
                    {t("showing_result_for")}
                    <span>&nbsp;{local_state}</span>
                    <span className="material-icons-round">chevron_right</span>
                    {local_district !== "District" && (
                      <>
                        <span>{local_district}</span>
                        <span className="material-icons-round">
                          chevron_right
                        </span>
                      </>
                    )}
                    {local_block !== "Block" && (
                      <>
                        <span>{local_block}</span>
                        <span className="material-icons-round">
                          chevron_right
                        </span>
                      </>
                    )}
                    <span>{local_year}</span>
                  </h4>
                )}
                <Tabs
                  defaultActiveKey={type}
                  id="uncontrolled-tab-example"
                  // onSelect={handleTopFiveTabs}
                  className="nav-absolute"
                  onSelect={(k) => setActiveTab(k)}
                >
                  <Tab eventKey="about" title={t("about")}>
                    <div className="about-card mt-4">
                      <h2 className="heading-sm2 mb-2">{t("about_us")}</h2>
                      <p> {t("about_us_reports.report_1005.para1")} </p>
                      <p> {t("about_us_reports.report_1005.para2")}</p>
                      <p> {t("about_us_reports.report_1005.para3")}</p>
                      <p> {t("about_us_reports.report_1005.para4")}</p>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="table"
                    title={t("table")}
                    className="tabledata-ukkl"
                  >
                    {/* {school_data?.isLoading && <GlobalLoading />} */}
                    <div
                      className="ag-theme-material ag-theme-custom-height ag-theme-quartz h-300"
                      style={{ height: 450 }}
                    >
                      <AgGridReact
                        key={gridRefreshKey}
                        rowData={[]}
                        getRowStyle={(params) => {
                          if (params.data.isTotalRow) {
                            return { fontWeight: "bold" }; // Apply inline bold style for totals row
                          }
                          return {}; // Default style for other rows
                        }}
                        columnDefs={columns}
                        defaultColDef={defColumnDefs}
                        onGridReady={onGridReady}
                        groupDisplayType="custom"
                        groupHideOpenParents={true}
                        // onColumnVisible={onColumnVisible}
                      />
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FilterDropdownCommom358 />
    </>
  );
}
